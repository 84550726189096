import React from 'react';
import '../../index.css';
import { motion } from "framer-motion";
import i18next from 'i18next'; // Importieren Sie i18next für die Übersetzungen

const ResellerSubMain3 = () => {
    return (
        <div className='pb-4'>
            <div className='flex flex-col justify-center items-center'>
                <h1 className='text-center font-bold text-[35px]'>{i18next.t('reselling.submain3.wantToLearnMore')}</h1>
            </div>
            <div className='flex md:flex-row flex-col justify-center items-center gap-8 pt-8 pb-8 w-[full]'>
                <motion.a
                    whileHover={{
                        scale: 1.1,
                        transition: 0.03
                    }}
                    whileTap={{
                        scale: 0.9,
                        transition: 0.03
                    }}
                    transition={{ ease: "easeOut", duration: 0.03 }}
                    href='/apidoku'
                    className='flex bg-[#3D73F5] md:w-[230px] text-center w-2/3 p-2.5 pl-5 pr-5 items-center justify-center rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'>
                    {i18next.t('reselling.submain3.apiDocumentation')}
                </motion.a>
                <motion.a
                    whileHover={{
                        scale: 1.1,
                        transition: 0.03
                    }}
                    whileTap={{
                        scale: 0.9,
                        transition: 0.03
                    }}
                    transition={{ ease: "easeOut", duration: 0.03 }}
                    href='/contact'
                    className='flex bg-[#3D73F5] md:w-[230px] text-center w-2/3 p-2.5 pl-5 pr-5 items-center justify-center rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'>
                    {i18next.t('reselling.submain3.becomeReseller')}
                </motion.a>
            </div>
        </div>
    )
}

export default ResellerSubMain3;
