import React, { useEffect } from 'react';
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import MainAgb from '../components/agb/mainAgb';
import i18next from "i18next";


const AGBPage = () => {

    useEffect(() => {

        document.title = i18next.t('agb');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>
            <Navbar />
            <MainAgb />
            <Footer />
        </main>
    )
}

export default AGBPage;