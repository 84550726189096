import React from 'react';
import Markdown from 'react-markdown';
import { useEffect, useState } from 'react';

const LoadingIndicator = () => {
    return (
        <div className="flex items-center justify-center h-screen space-x-10 animate-pulse">
            <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
            <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
            <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
        </div>
    );
};

const AGBChanges = () => {
    return (
        <div id='impress' className="main p-4 md:p-8 lg:p-12 w-full min-h-screen-minus-navbar flex justify-center text-gray-800">
            <div className="max-w-4xl w-full">
                <h1 className="text-3xl font-bold mb-6">Änderungen der AGB von Cordes-Hosting</h1>

                <div className="mb-8">
                    <p className="mb-4">
                        <strong>Stand der neuen AGB:</strong> 01.04.2025<br />
                        <strong>Inkrafttreten:</strong> 10.05.2025
                    </p>
                    <p className="mb-6">Wir haben unsere Allgemeinen Geschäftsbedingungen (AGB) vollständig neu strukturiert, sprachlich überarbeitet und an aktuelle rechtliche sowie technische Anforderungen angepasst. Im Folgenden finden Sie eine Übersicht der wesentlichen Änderungen:</p>
                </div>

                <div className="space-y-8">
                    {[
                        {
                            title: "1. Klarere Struktur",
                            items: [
                                "Die AGB sind jetzt in 17 übersichtliche Abschnitte gegliedert.",
                                "Veraltete, internationale und irrelevante Klauseln wurden entfernt."
                            ]
                        },
                        {
                            title: "2. Zahlungsbedingungen",
                            items: [
                                "Einführung einer verbindlichen Zahlungsfrist von 10 Tagen.",
                                "Klare Regelung zur Prepaid-Verwaltung im Kundenpanel.",
                                "Sperrgebühr in Höhe von 49 € bei Panel-Sperrung definiert."
                            ]
                        },
                        {
                            title: "3. Domainverwaltung",
                            items: [
                                "Kundenpanel-Domains: Automatische Löschung bei Nichtverlängerung.",
                                "Reseller-Domains: Automatische Verlängerung um 1 Jahr bei Nichtkündigung."
                            ]
                        },
                        {
                            title: "4. Vertragslaufzeit und Kündigung",
                            items: [
                                "Standardkündigungsfrist: 14 Tage zum Monatsende.",
                                "Fristlose Kündigung bei wiederholtem Zahlungsverzug möglich."
                            ]
                        },
                        {
                            title: "5. Leistung & Verfügbarkeit",
                            items: [
                                "Garantierte Systemverfügbarkeit von 99,8 % im Monatsmittel.",
                                "Klarstellung zur Änderung von IP-Adressen und Backup-Verantwortung."
                            ]
                        },
                        {
                            title: "6. Pflichten und Nutzung",
                            items: [
                                "Verbot bestimmter Inhalte und Systembelastungen konkretisiert.",
                                "Klauseln zu Missbrauch und Vertragsverstößen präzisiert."
                            ]
                        },
                        {
                            title: "7. Datenschutz und Support",
                            items: [
                                "Verweis auf separate Datenschutzerklärung.",
                                "Supportzeiten definiert: Mo–Fr, 09:00–22:00 Uhr."
                            ]
                        },
                        {
                            title: "8. Haftung",
                            items: [
                                "Haftung auf grobe Fahrlässigkeit, Vorsatz und Personenschäden beschränkt."
                            ]
                        }
                    ].map((section, index) => (
                        <div key={index} className="border-b pb-6 last:border-b-0">
                            <h2 className="text-xl font-bold mb-3">{section.title}</h2>
                            <ul className="list-disc pl-5 space-y-2">
                                {section.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className="mt-8 space-y-4">
                    <p>Die vollständige Fassung der neuen AGB finden Sie hier:<br />
                        <a href="https://cordes-hosting.de/agb" target="_blank" rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800">cordes-hosting.de/agb</a>
                    </p>

                    <p>Die alte AGB findest du hier:<br />
                        <a href="https://www.iubenda.com/nutzungsbedingungen/87094575" target="_blank" rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800">Zu der alten AGB</a>
                    </p>

                    <div className="mt-4 space-y-2">
                        <p>Du kannst den neuen AGB widersprechen, indem du uns bis spätestens <strong>01.05.2025</strong> eine E-Mail mit dem Betreff „Widerspruch AGB" sendest – idealerweise von deiner bei uns registrierten Adresse.</p>

                        <p className="font-medium">➤ E-Mail-Adresse für den Widerspruch: <a href="mailto:support@cordes-hosting.net" className="text-blue-600 hover:text-blue-800">support@cordes-hosting.net</a></p>

                        <p>Ein einfacher Satz genügt, z. B.:<br />
                            <em>„Ich widerspreche den neuen AGB von Cordes-Hosting (Stand 01.04.2025)."</em></p>
                    </div>
                </div>

                <footer className="mt-12 text-center text-gray-600 border-t pt-4">
                    © 2025 Cordes-Hosting · Inhaber: Marvin Cordes
                </footer>
            </div>
        </div>
    );
};

export default AGBChanges;