import React, { useEffect, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../index.css";

// import required modules
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import i18n from "i18next";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { BsFillHeartPulseFill } from "react-icons/bs";

import Logo from "../../assets/img/logo.webp";

const Footer = () => {
  const { t } = useTranslation();
  const [jsonData2, setJsonData2] = useState(null);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://api.cordes-hosting.net/website/api/partner", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setJsonData2(data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <footer className="bg-gray-800 dark:bg-gray-800 dark:border-gray-700 !bg-opacity-90 max-[394px]:mt-40 md:mt-0">
      <div className="!flex !flex-col !justify-center !items-center h-64 !w-full pl-8 pr-8">
        {Array.isArray(jsonData2) ? (
          <Swiper
            pagination={false}
            loop={true}
            autoplay={true}
            speed={1200}
            modules={[Pagination, Autoplay]}
            spaceBetween={22}
            slidesPerView={1}
            className="mySwiper !flex w-full !flex-col !justify-center !items-center rounded-2xl"
          >
            {jsonData2.map((item, index) => (
              <SwiperSlide
                key={index}
                className="!flex !justify-center !items-center w-full h-2/3 bg-gray-700 rounded-2xl !bg-transparent"
              >
                <div className="!flex !justify-center !items-center pt-8 flex-col w-full text-gray-200">
                  <a
                    href={item.options.url}
                    target="_blank"
                    className="flex flex-col items-center justify-center"
                    data-tianji-event={`Clicked on Partner: ${item.name}!`}
                  >
                    <img
                      src={item.options.img_icon}
                      className="!w-36"
                      alt={item.options.img_banner}
                    />
                    <h1 className="font-bold mt-4 text-xl">{item.name}</h1>
                    <p className="custom-p text-gray-200 !w-full">
                      {i18n.language === "de" || i18n.language === "de-DE"
                        ? item.options.descripton
                        : item.options.descriptionEn}
                    </p>
                  </a>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide className="!flex !justify-center !items-center w-full h-2/3 bg-gray-100 dark:bg-gray-700 rounded-2xl !bg-transparent">
              <div className="!flex !justify-center !items-center flex-col w-48 text-gray-200">
                <Link
                  to="/contact"
                >
                  <img
                    src="https://image.cordes-cloud.net/i/9f5046e8-b801-4112-bd08-1bfd31a6b5c0.webp"
                    alt="Jetzt Partner werden!"
                  />
                  <h1 className="font-bold text-xl mt-4">
                    {i18next.t("footer.become_partner")}
                  </h1>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        ) : (
          <div className="text-center"></div>
        )}
      </div>
      <div className="bg-gray-700 dark:bg-gray-700">
        <div className="mx-auto w-full max-w-screen-xl bg-gray-700 dark:bg-gray-700">
          <div className="grid grid-cols-1 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
            <div className="flex flex-col gap-6 md:justify-start justify-center text-center md:text-start items-center">
              <img src={Logo} style={{ width: "130px" }} className="" />
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">Wormser Str. 36, 76185 Karlsruhe</li>
                <li className="mb-4">
                  <a
                    className="hover:underline"
                    href="mailto:vertrieb@cordes-hosting.de"
                  >
                    vertrieb@cordes-hosting.de
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://cordes-hosting.de/"
                    className="hover:underline"
                  >
                    www.cordes-hosting.de
                  </a>
                </li>
                <li className="mb-4">
                  <a href="tel:+497214807690" className="hover:underline">
                    +49 (0) 721 4807690
                  </a>
                </li>
              </ul>
            </div>
            <div className="!text-center">
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
                {i18next.t("footer.company")}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <Link to="/aboutus">
                  <li className="mb-4">
                    <p href="#" className=" hover:underline">
                      {i18next.t("footer.aboutus")}
                    </p>
                  </li>
                </Link>
                <Link to="/contact">
                  <li className="mb-4">
                    <p className="hover:underline">
                      {i18next.t("footer.contact")}
                    </p>
                  </li>
                </Link>
                <li className="mb-4">
                  <a
                    href="https://dashboard.cordes-hosting.net/"
                    target="_blank"
                    className="hover:underline"
                  >
                    {i18next.t("footer.dashboard")}
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://support.cordes-hosting.net/"
                    target="_blank"
                    className="hover:underline"
                  >
                    {i18next.t("footer.ticketsystem")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="!text-center">
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
                {i18next.t("footer.resource")}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <Link to="/resource">
                  <li className="mb-4 hover:underline">
                    {i18next.t("footer.resourcePack")}
                  </li>
                </Link>
              </ul>
            </div>
            <div className="!text-center">
              <h2 className="mb-6 text-sm font-semibold text-white uppercase dark:text-white">
                {i18next.t("footer.legal")}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <Link to="/impress">
                  <li className="mb-4 hover:underline">
                    {i18next.t("footer.impress")}
                  </li>
                </Link>
                <li className="mb-4">
                  <Link
                    to="/agb"
                    className="hover:underline"
                  >
                    {i18next.t("footer.terms")}
                  </Link>
                </li>
                <Link to="/impress">
                  <li className="mb-4 hover:underline">
                    {i18next.t("footer.privacypolicy")}
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="px-4 py-6 bg-gray-700 text-center dark:bg-gray-700 md:flex md:items-center justify-between">
            <span className="text-sm md:text-start w-full text-gray-500 dark:text-gray-300 text-center">
              © {new Date().getFullYear()}{" "}
              <a href="https://cordes-hosting.net/">Cordes-Hosting™</a>.{" "}
              {i18next.t("footer.rights")}
            </span>
            <div className="md:flex grid grid-cols-3 mt-12 space-x-5 justify-items-center gap-6 md:gap-0 md:mt-0">
              <a
                href="https://www.facebook.com/cordeshosting"
                target="_blank"
                className="text-gray-400 hover:text-gray-900 ml-5 dark:hover:text-white"
                data-tianji-event={`Clicked in Footer to Facebook!`}
              >
                <svg
                  className="w-8 h-8 md:w-4 md:h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 8 19"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
              <a
                href="https://www.instagram.com/cordeshosting/"
                target="_blank"
                className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
                data-tianji-event="Clicked in Footer to Instagram!"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 md:w-4 md:h-4"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
                <span className="sr-only">Instagram</span>
              </a>
              <a
                href="https://discord.gg/pfMDy29YGQ"
                target="_blank"
                className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
                data-tianji-event="Clicked in Footer to Discord!"
              >
                <svg
                  className="w-8 h-8 md:w-4 md:h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 21 16"
                >
                  <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                </svg>
                <span className="sr-only">Discord community</span>
              </a>
              <a
                href="https://twitter.com/CordesHosting"
                target="_blank"
                className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
                data-tianji-event="Clicked in Footer to Twitter!"
              >
                <svg
                  className="w-8 h-8 md:w-4 md:h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a>
              <a
                href="https://www.linkedin.com/company/84540265"
                target="_blank"
                className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
                data-tianji-event={`Clicked in Footer to Twitter!`}
              >
                <svg
                  className="w-8 h-8 md:w-4 md:h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fill-rule="evenodd"
                    d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="sr-only">LinkedIn page</span>
              </a>
              <a
                href="ts3server://45.82.122.91?port=9987"
                className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
                data-tianji-event={`Clicked in Footer to Teamspeak!`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 md:w-4 md:h-4"
                  fill="currentColor"
                  viewBox="0 0 28 28"
                >
                  <path d="M16.375 3.998c-1.297-.023-2.648.18-4.055.611c-1.88.58-3.47 1.67-4.79 3.16c-.18.21-.31.43-.14.68c.14.19.06.292-.04.442c-.93 1.3-1.56 2.728-1.85 4.298c-.03.2-.13.282-.3.362c-.66.33-1.05.87-1.16 1.6L4 16.39v1.298c0 1.44.98 2.361 2.43 2.301c.36-.02.57-.21.57-.54v-4.378c0-.025.047-1.265.16-1.842c.234-1.176.65-2.156 1.195-2.962C9.942 7.71 12.768 6 16 6a9 9 0 0 1 9 9v4.29c0 .33.08.59.42.67c1.13.23 2.31-.56 2.5-1.72c.13-.8.04-1.94.04-2.75c-.01-.88-.4-1.56-1.22-1.97c-.13-.06-.23-.13-.27-.3a10.46 10.46 0 0 0-1.9-4.39c-.05-.07-.16-.15-.1-.23c.4-.54-.01-.85-.33-1.15c-2.323-2.249-4.912-3.4-7.765-3.452m-6.016 12.004c-.63-.04-1.138.37-1.279 1.094c-.214 1.04 0 2.038.607 2.935c.42.61 1.066.898 1.817.95c.483.024 2.2.091 2.584-.036c.5-.178.84-.474.947-.965c.098-.49-.51-1.192-1.36-1.937c-.875-.762-2.02-1.55-2.655-1.846a1.667 1.667 0 0 0-.66-.195zm11.623 2.006c-.936-.086-2.266.53-2.867.78c-3.233 1.298-1.986 2.547-.978 2.825l2.281.358c1.611.128 2.618-1.33 2.58-2.819c-.035-.788-.454-1.092-1.016-1.144m2.315 2.994a.235.235 0 0 0-.162.084c-.02.03-.03.062-.04.082c-.368 1.231-2.038 5.417-7.833 5.848c-6.813.502 3.65 2.092 6.62-.309c1.03-.842 2.165-1.682 2.116-4.103c-.019-.493-.222-1.283-.523-1.55a.256.256 0 0 0-.178-.052" />
                </svg>
                <span className="sr-only">Teamspeak</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="!flex md:flex-row flex-col !justify-center gap-1.5 !items-center w-full md:h-[40px] h-[120px] bg-gray-700 rounded-2xl !bg-transparent">
        <h2 className="text-gray-100">Programmed with</h2>
        <BsFillHeartPulseFill className="text-red-500 md:inline-block hidden" />
        <BsFillHeartPulseFill className="text-red-500 text-2xl inline-block md:hidden" />
        <a
          href="https://steven-dautrich.de"
          target="_blank"
          className="text-gray-100 hover:text-gray-300"
        >
          by Steven Dautrich
        </a>
      </div>
    </footer>
  );
};

export default Footer;
