import React, { useEffect } from 'react';
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import AGBChanges from '../components/agbchanges/agbchanges';
import i18next from "i18next";

const AGBChangesMain = () => {

    useEffect(() => {

        document.title = i18next.t('agbChanges');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>
            <Navbar />
            <AGBChanges />
            <Footer />
        </main>
    )
}

export default AGBChangesMain;