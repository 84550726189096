import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Reselling from "./pages/reselling";
import Register from "./pages/register";
import Blog from "./pages/blog";
import Technic from "./pages/technik";
import Domains from "./pages/domains";
import ApiDoku from "./pages/apidoku";
import ServerStatus from "./pages/serverstatus";
import { Helmet } from "react-helmet";
import Rz from "./pages/rz";
import ActivateAcc from "./pages/activateAcc";
import Impress from "./pages/impress";
import Contact from "./pages/contact";
import ProductPort from "./pages/productPort";
import { useTranslation } from "react-i18next"; // Importieren Sie useTranslation aus react-i18next
import i18n from "./useTranslations";
import Resource from "./pages/resource";
import Support from "./pages/support";
import Meeting from "./pages/meeting"; // Importieren Sie Ihre i18n-Konfiguration
import UptimeTracker from "./functions/tracking";
import AGBChanges from "./pages/agbchanges";
import AGBPage from "./pages/agbPage";

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    // Laden des i18next-Scripts
    import("i18next-http-backend").then((module) => {
      i18n.use(module.default).init({
        backend: {
          loadPath: "./configs/languages/{{ns}}.json", // Pfad zu Ihren Übersetzungsdateien
        },
      });
    });
  }, []);

  useEffect(() => {
    (function (w, d, s, u) {
      w.ZammadChat = function (c) {
        w.ZammadChat._.push(c);
      };
      w.ZammadChat._ = [];
      w.ZammadChat.url = u;
      var h = d.getElementsByTagName(s)[0],
        j = d.createElement(s);
      j.async = true;
      j.src =
        "https://ticket.cordes-hosting.net/assets/chat/chat-no-jquery.min.js";
      j.onload = () => {
        if (typeof window.ZammadChat === "function") {
          new window.ZammadChat({
            fontSize: "12px",
            chatId: 1,
            debug: false,
            host: "wss://ticket.cordes-hosting.net/ws",
            show: true,
            target: document.body,
            title: "Support-Chat",
          });
        }
      };
      h.parentNode.insertBefore(j, h);
    })(window, document, "script", "https://chat.cordes-hosting.net/livechat");

    return () => {
      const script = document.querySelector(
        'script[src="https://ticket.cordes-hosting.net/assets/chat/chat-no-jquery.min.js"]'
      );
      if (script) {
        script.parentNode.removeChild(script);
      } else {
        return;
      }
    };
  }, []);

  return (
    <Router>
      <UptimeTracker />
      <Helmet>
        <meta
          name="description"
          content="Cordes-Hosting bietet professionelle Webhosting-Lösungen, Domainregistrierung und dedizierte Server mit erstklassigem Support und höchster Verfügbarkeit."
        />
        <meta
          name="keywords"
          content="Webhosting, Domains, Serverhosting, IT-Infrastruktur, Cordes-Hosting, Cloud-Services"
        />
        <meta name="author" content="Steven Dautrich" />
        <meta name="robots" content="index,follow" />
        <meta name="theme-color" content="#000000" />
        <meta
          property="og:description"
          content="Cordes-Hosting bietet professionelle Webhosting-Lösungen, Domainregistrierung und dedizierte Server mit erstklassigem Support und höchster Verfügbarkeit."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.cordes-hosting.de" />
        <meta
          property="og:image"
          content="https://www.cordes-hosting.net/logo.png"
        />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/reselling" component={Reselling} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/technic" component={Technic} />
        <Route exact path="/domains" component={Domains} />
        <Route exact path="/apidoku" component={ApiDoku} />
        <Route exact path="/status" component={ServerStatus} />
        <Route exact path="/rz" component={Rz} />
        <Route exact path="/activateAccount/:token" component={ActivateAcc} />
        <Route exact path="/impress" component={Impress} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/productPort" component={ProductPort} />
        <Route exact path="/resource" component={Resource} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/meeting" component={Meeting} />
        <Route exact path="/agbChanges" component={AGBChanges} />
        <Route exact path="/agb" component={AGBPage} />
      </Switch>
    </Router>
  );
}

export default App;
