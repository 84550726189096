import React from 'react';
import ReactTyped from "react-typed";
import { motion } from "framer-motion"

import '../../index.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";


const Main = () => {

    const { t } = useTranslation();

    return (

        <div className='w-full flex flex-col background h-screen-minus-navbar items-center justify-center md:justify-center gap-6'>
            <div className='flex flex-col justify-center items-center w-full h-full gap-6'>
                <h1 className='text-gray-50 md:text-[45px] text-[35px] text-center w-full md:w-2/3 font-bold pl-4 pr-4 md:pr-0 md:pl-0'>
                    <ReactTyped strings={[i18next.t('home.main.main_text_header')]} typeSpeed={60} />
                </h1>
                <motion.p animate={{ y: [40, 0] }}
                    transition={{ ease: "easeIn", duration: 0.9 }}
                    className='font-bold pl-8 pr-8 md:pr-0 md:pl-0 text-gray-50 text-center w-full md:pb-8 md:w-2/3'>
                    {i18next.t('home.main.main_text')}
                </motion.p>

                <div className='flex md:flex-row flex-col gap-6 justify-center items-center'>
                    <Link to='/contact'>
                        <motion.a
                            whileHover={{
                                scale: 1.1,
                                transition: 0.03
                            }}
                            whileTap={{
                                scale: 0.9,
                                transition: 0.03
                            }}
                            transition={{ ease: "easeOut", duration: 0.03 }}
                            href=''
                            className='inline-block bg-[#3D73F5] p-1.5 pl-5 pr-5 rounded-3xl hover:bg-[#4169FC] duration-300 text-gray-50 font-bold'>
                            {i18next.t('home.main.contact_button')}
                        </motion.a>
                    </Link>

                </div>
            </div>
        </div>


    );
};

export default Main;
