import React from 'react';
import Markdown from 'react-markdown';
import { useEffect, useState } from 'react';
import { set } from 'react-hook-form';

const LoadingIndicator = () => {
    return (
        <div className="flex items-center justify-center h-screen space-x-10 animate-pulse">
            <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
            <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
            <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
        </div>
    );
};

const MainImpress = () => {
    const [impress, setImpress] = useState('');
    const [privacy, setPrivacy] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const apiUrl = 'https://api.cordes-hosting.net/website/api/privacy';

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setImpress(data.impress);
                setPrivacy(data.privacy_policy);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Daten:', error);
                setIsLoading(false);
            });
    }, []);

    return (
        <div id='impress' className="main p-4 md:p-8 lg:p-12 w-full min-h-screen-minus-navbar flex flex-col sm:flex-row items-start justify-center gap-8 text-gray-800">
            {isLoading ? (
                <div className="flex items-center justify-center h-full w-full">
                    <LoadingIndicator />
                </div>
            ) : (
                <>
                    <div className='w-full sm:w-[45%] flex flex-col gap-4'>
                        <div className='border rounded-lg border-gray-300
                                    shadow-lg overflow-y-auto h-[70vh] p-6 
                                    scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200'>
                            <Markdown className="prose">{impress}</Markdown>
                        </div>
                    </div>
                    <div className='w-full sm:w-[45%] flex flex-col gap-4'>
                        <div className='border rounded-lg border-gray-300
                                    shadow-lg overflow-y-auto h-[70vh] p-6 
                                    scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200'>
                            <Markdown className="prose">{privacy}</Markdown>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default MainImpress